'use client';
import SafeImage from '@ui/SafeImage';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import yamaha from '../i/dirtyBikes/yamaha.svg';
import suzuki from '../i/dirtyBikes/suzuki.svg';
import kawasaki from '../i/dirtyBikes/kawasaki.svg';
import honda from '../i/dirtyBikes/honda.svg';
import beta from '../i/dirtyBikes/beta.png';
import gasgas from '../i/dirtyBikes/gasgas.png';
import husqvarna from '../i/dirtyBikes/husqvarna.svg';
import sherco from '../i/dirtyBikes/sherco.png';
import ktm from '../i/dirtyBikes/KTM.svg';
import kingcobra from '../i/dirtyBikes/kingcobra.webp';
import Arrow from './icons/buttons/ArrowButton';
import { useRef } from 'react';

const BRANDS = [
  'yamaha',
  'suzuki',
  'kawasaki',
  'honda',
  'beta',
  'gasgas',
  'husqvarna',
  'sherco',
  'ktm',
  'kingcobra',
] as const;
export type Brand = (typeof BRANDS)[number];

export function getBrandAssets(b: Brand): {
  width: number;
  height: number;
  src: StaticImageData;
  brand: Brand;
} | null {
  switch (b) {
    case 'yamaha':
      return {
        brand: b,
        width: 160,
        height: 46,
        src: yamaha,
      };
    case 'suzuki':
      return {
        brand: b,
        width: 160,
        height: 30,
        src: suzuki,
      };
    case 'kawasaki':
      return {
        brand: b,
        width: 160,
        height: 18,
        src: kawasaki,
      };
    case 'honda':
      return {
        brand: b,
        width: 160,
        height: 20,
        src: honda,
      };
    case 'beta':
      return {
        brand: b,
        width: 120,
        height: 72,
        src: beta,
      };
    case 'gasgas':
      return {
        brand: b,
        width: 120,
        height: 72,
        src: gasgas,
      };
    case 'husqvarna':
      return {
        brand: b,
        width: 132,
        height: 78,
        src: husqvarna,
      };
    case 'sherco':
      return {
        brand: b,
        width: 150,
        height: 17,
        src: sherco,
      };
    case 'ktm':
      return {
        brand: b,
        width: 120,
        height: 38,
        src: ktm,
      };
    case 'kingcobra':
      return {
        brand: b,
        width: 150,
        height: 82,
        src: kingcobra,
      };
    default:
      return null;
  }
}

const ShopByManufacturer = ({ title }: { title?: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="flex justify-between gap-[0.6rem]">
        <h3 className="ml-4 text-[2.4rem] font-semibold text-gray-900">
          {title ?? 'Shop by Manufacturer'}
        </h3>
        <div className="hidden gap-[0.8rem] lg:flex">
          <Arrow
            variant="left"
            onClick={() => {
              ref.current?.scrollTo({
                left: 0,
              });
            }}
          />
          <Arrow
            variant="right"
            onClick={() => {
              ref.current?.scrollTo({
                left: 1000,
              });
            }}
          />
        </div>
      </div>
      {/* desktop */}
      <div
        className="flex justify-items-center gap-[0.8rem] overflow-scroll scroll-smooth px-8 lg:-mt-[9.6rem] lg:overflow-hidden"
        ref={ref}
      >
        {BRANDS.map((brand, index) => {
          const brandAssets = getBrandAssets(brand);
          return (
            brandAssets && (
              <Link
                href={`/shop/${brand}-dirt-bike-parts/`}
                className="mb-4 flex h-[15rem] w-[15rem] shrink-0 items-center justify-center self-center overflow-clip  opacity-70  transition-all duration-150 hover:opacity-100 hover:grayscale-0 sm:h-[20rem] sm:w-[20rem]  lg:grayscale"
                key={brand + '-' + index}
              >
                <SafeImage
                  src={brandAssets.src}
                  alt={brand}
                  width={brandAssets.width}
                  height={brandAssets.height}
                />
              </Link>
            )
          );
        })}
      </div>
    </>
  );
};

export default ShopByManufacturer;
