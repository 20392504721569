import SafeImage from '@ui/SafeImage';
import { useAuth } from 'context/AuthContext';
import HomeSection from 'features/homepage/components/Home/components/HomeSection';
import Link from 'next/link';

const PopularCategories = () => {
  const { userDoc } = useAuth();
  return (
    <HomeSection title="Popular Categories">
      <div className="max-w-[100%] overflow-x-scroll">
        <div className="flex w-[80rem] flex-wrap gap-[1rem] px-[1.6rem] py-4 sm:grid sm:w-full sm:grid-cols-4">
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/boots_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Boots"
            classes="bg-[#5D5D5D] text-white "
            href={userDoc ? `/search?keyword=boots` : `/shop/riding-gear/boots`}
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/helmets_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Helmets"
            classes="bg-[#647491] text-white"
            href={
              userDoc ? `/search?keyword=helmets` : '/shop/riding-gear/helmets'
            }
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/suspension_1920x2560.png?width=320&t=323&optimizer=image'
            }
            name="Suspension"
            classes="bg-[#008354] text-white"
            href={'/shop/dirt-bike-parts/suspension'}
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/jersey_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Collectibles"
            classes="bg-[#C53D88] text-white"
            href="/shop/collectibles"
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/exhaust_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Exhaust"
            classes="bg-[#7B7A00] text-white"
            href={
              userDoc
                ? `/search?keyword=exhaust`
                : '/shop/dirt-bike-parts/exhaust'
            }
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/ktm_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="KTM Parts"
            classes="bg-[#CB9347] text-white"
            href={userDoc ? `/search?keyword=ktm` : '/shop/ktm-dirt-bike-parts'}
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/yamaha_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Yamaha Parts"
            classes="bg-[#007BA9] text-white"
            href={
              userDoc
                ? `/search?keyword=yamaha`
                : '/shop/yamaha-dirt-bike-parts'
            }
          />
          <CategoryPill
            src={
              'https://cdn.mxlocker.com/assets/popular_categories/alpinestars_1920x2560.png?width=320&t=320&optimizer=image'
            }
            name="Alpinestars"
            classes="bg-[#C12A2A] text-white"
            href={
              userDoc ? `/search?keyword=alpinestars` : '/brands/alpinestars'
            }
          />
        </div>
      </div>
    </HomeSection>
  );
};

const CategoryPill = ({
  src,
  name,
  classes,
  href,
}: {
  name: string;
  src: string;
  classes: string;
  href: string;
}) => {
  return (
    <Link
      href={href}
      className={`flex h-[5.5rem] items-center gap-2 overflow-clip rounded-[1rem] pr-4 font-semibold  sm:h-[9rem] sm:w-[36rem] ${classes} group relative  transition  hover:bg-[#f1f1f1] hover:text-black `}
    >
      <div className="relative aspect-square w-[5.5rem] transition-transform group-hover:scale-110 sm:w-[9rem]">
        <SafeImage src={src} fill alt={name + ' image'} id="mobile" />
      </div>
      
      <span className="text-[1.875rem] transition-transform group-hover:scale-110 sm:text-[2.125rem]">
        {name}
      </span>
    </Link>
  );
};
export default PopularCategories;
