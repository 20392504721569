import { ChevronLeftIcon, ChevronRightIcon } from '..';

interface ArrowProps {
  variant: 'left' | 'right';
  onClick: () => void;
}

const Arrow = ({ variant, onClick }: ArrowProps) => {
  const Icon = variant === 'left' ? ChevronLeftIcon : ChevronRightIcon;
  const handleClick = () => {
    onClick();
  };
  return (
    <button
      className="flex h-[4.8rem] min-h-[1.6rem] w-[4.8rem] min-w-[1.6rem] cursor-pointer items-center justify-center rounded-full  bg-brand-secondary p-[1.2rem] text-brand-white transition-all hover:bg-brand-primary"
      onClick={handleClick}
    >
      <Icon />
    </button>
  );
};

export default Arrow;
